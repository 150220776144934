<template>
  <v-container class="pa-0 ma-0" fluid>
    <attendance-toolbar />
    <attendance-table />
  </v-container>
</template>

<script>
import AttendanceToolbar from './components/AttendanceToolbar.vue';
import AttendanceTable from './components/AttendanceTable.vue';

export default {
  components: {
    AttendanceToolbar,
    AttendanceTable,
  },
};
</script>
