<template>
  <v-row no-gutters class="d-print-none">
    <v-col cols="5" class="pa-0 ma-4 mx-3">
      <department-and-team-selector width="350px"
    /></v-col>
    <v-col>
      <p class="text-right font-weight-regular ma-2 mx-4" style="font-size:40px">
        {{ $d(currentDateTime, 'currentDateTime') }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import DepartmentAndTeamSelector from '../../../components/DepartmentAndTeamSelector.vue';

export default {
  components: {
    DepartmentAndTeamSelector,
  },
  data() {
    return {
      currentDateTime: new Date(),
    };
  },
  created() {
    setInterval(() => {
      this.getCurrentDateTime();
    }, 1000);
  },
  methods: {
    getCurrentDateTime() {
      this.currentDateTime = App.helpers.getDateObject(new Date());
    },
  },
};
</script>

