<template>
  <v-card outlined tile>
    <v-data-table
      :headers="headers"
      :items="membersStatus"
      disable-pagination
      disable-sort
      class="border-left"
      hide-default-footer
    >
      <template #[`item.status`]="{ item }">
        <v-chip :color="statusTypes[item.status].color" :style="elementStyle">
          {{ $t(`status.${statusTypes[item.status].name}`) }}
        </v-chip>
      </template>
      <template #[`item.status_updated_at`]="{ item }">
        {{
          item.status_updated_at === null
            ? '-'
            : $d(App.helpers.getDateObject(item.status_updated_at), 'eventTime')
        }}
      </template>
      <template #[`item.events`]="{ item }">
        <v-chip
          v-for="(event, index) in item.events"
          :key="index"
          label
          class="ma-1"
          style="width: 100%"
        >
          <span class="font-weight-bold pr-4">
            {{ getTimeDisplay(event.start, event.end, event.all_day === 1) }}
          </span>
          {{ event.subject }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { isSameDay } from 'date-fns';
import { mapGetters } from 'vuex';

import dataRefresher from '../../../mixins/dataRefresher';

export default {
  mixins: [dataRefresher],

  data() {
    return {
      attendances: [],
      elementStyle: 'word-break: keep-all',
    };
  },

  computed: {
    ...mapGetters('groups', ['selectedGroup']),
    ...mapGetters([
      'id',
      'departmentUsers',
      'teamUsers',
      'statusTypes',
      'membersStatus',
    ]),
    members() {
      if (this.selectedGroup === null) {
        this.$store.dispatch('groups/resetSelectedGroup');
      }
      let members =
        this.selectedGroup.type === 'department'
          ? [...this.departmentUsers(this.selectedGroup.id)]
          : [...this.teamUsers(this.selectedGroup.id)];
      members = members.map((member) => {
        if (member.id === this.id) {
          member.position = -1;
        }
        return member;
      });
      return members.map((member) => member.id);
    },
    headers() {
      return [
        { text: this.$t('status.members'), value: 'name', width: '25%' },
        {
          text: this.$t('status.attendanceStatus'),
          value: 'status',
          align: 'center',
          width: '15%',
        },
        {
          text: this.$t('status.updatedAt'),
          value: 'status_updated_at',
          align: 'center',
          width: '15%',
        },
        { text: this.$t('status.events'), value: 'events', align: 'center' },
      ];
    },
  },

  watch: {
    members() {
      this.getAttendance();
    },
  },

  mounted() {
    this.setUpRefresher(this.getAttendance);
  },

  created() {
    this.getAttendance();
  },

  methods: {
    getAttendance() {
      this.$store.dispatch('fetchAndSetMembersStatus', this.members);
    },

    getTimeDisplay(startInitial, endInitial, allDay) {
      const start = App.helpers.getDateObject(startInitial);
      const end = App.helpers.getDateObject(endInitial);
      if (!allDay) {
        if (isSameDay(start, end)) {
          return `${this.$d(start, 'eventTime')} ~ ${this.$d(end, 'timeOnly')}`;
        }
        return `${this.$d(start, 'eventTime')} ~
            ${this.$d(end, 'eventTime')}`;
      }
      if (isSameDay(start, end)) {
        return `${this.$d(start, 'allDayEvent')}`;
      }
      return `${this.$d(start, 'allDayEvent')} ~
            ${this.$d(end, 'allDayEvent')}`;
    },
  },
};
</script>

<style>
.border-left th + th {
  border-left: 1px solid #dddddd !important;
}
.border-left td + td {
  border-left: 1px solid #dddddd !important;
}
</style>
